/** @format */

import React from "react";
import {
  Box,
  Text,
  Heading,
  VStack,
  Button,
  useDisclosure,
} from "@chakra-ui/react";
import ContactForm from "../ContactForm";

const BuildWithUs = () => {
  const {onOpen, isOpen, onClose} = useDisclosure();

  return (
    <VStack spacing={4} my={4}>
      <Heading textAlign="center" color="teal.500" fontSize="6xl">
        Build with Us
      </Heading>
      <Text fontSize="lg" fontWeight="500" lineHeight={2}>
        We are{" "}
        <Text as="span" color={"red.500"} fontWeight="bold">
          designers
        </Text>
        ,{" "}
        <Text as="span" color={"pink.500"} fontWeight="bold">
          developers
        </Text>{" "}
        and{" "}
        <Text as="span" color={"yellow.300"} fontWeight="bold">
          architects
        </Text>{" "}
        with different backgrounds and expertise merged into one with the aim of
        building softwares that can empower buisiness.
      </Text>
      <Box align="center" my={8}>
        <Button variant="contact" onClick={onOpen}>
          Contact us
        </Button>
        <ContactForm isOpen={isOpen} onClose={onClose} />
      </Box>
    </VStack>
  );
};

export default BuildWithUs;
