/** @format */

import React from "react";
import {
  Container,
  AspectRatio,
  Heading,
  Image,
  Text,
  HStack,
  Flex,
  VStack,
} from "@chakra-ui/react";
import {CheckCircleIcon} from "@chakra-ui/icons";
import ServiceImage from "../static/images/service.jpg";
import {KeyServices} from "../utils/helpers";
import BuildWithUs from "../components/BuildWithUs/build-with-us";
import SEO from "../components/seo";

const Services = () => {
  return (
    <>
      <AspectRatio ratio={{base: 6 / 4, md: 12 / 4}}>
        <Image src={ServiceImage} alt="naruto" objectFit="cover" />
      </AspectRatio>

      <Container>
        {KeyServices.map(({heading, description, serviceAreas}) => (
          <VStack
            key={heading}
            justify={{md: "center"}}
            spacing={5}
            my={5}
            px={4}
            align="start"
          >
            <Heading alignSelf="center">{heading}</Heading>
            <Text align="center" width="100%">
              {description}
            </Text>
            <Flex wrap="wrap" alignSelf="center">
              {!!serviceAreas &&
                serviceAreas.map((area) => (
                  <HStack key={area} spacing={4} flex="50%" my={8}>
                    <CheckCircleIcon w={8} h={8} />
                    <Text fontWeight="extrabold">{area}</Text>
                  </HStack>
                ))}
            </Flex>
          </VStack>
        ))}

        <BuildWithUs />
      </Container>
    </>
  );
};

export default Services;

export const Head = () => <SEO title="- Your trusted IT consult - Services" />;
